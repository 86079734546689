<template>
  <div class="p-0 m-0">
    <div class="mb-3" :class="{'form-inline d-flex': isInline}" v-if="isInline">
      <label
          :for="name"
          class="d-inline-block col-form-label font-size-lg"
          style="width: auto !important;"
          :class="{
          'col-form-label-sm': size.toString() === 'sm',
          'col-form-label-lg': size.toString() === 'lg',
        }">
        {{ title }}
        <span class="text-danger" v-if="required">*</span>
      </label>
      <div class="form-group d-inline-block" style="flex-grow: 1;"
           :class="{'mx-3': isInline}"
      >

        <textarea
            :class="{
                    'is-valid': isValid === true,
                    'is-invalid': isValid === false,
                    'form-control-sm': size.toString() === 'sm',
                    'form-control-lg': size.toString() === 'lg',
                   }"
            :id="name"
            :rows="rows"
            :name="name"
            class="form-control w-100"
            :required="required"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="modelProxy"></textarea>
        <span class="form-text text-muted"
              :class="{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': isInline}">{{ hint }}</span>
        <p class="text-danger pt-1 px-3 block" :class="{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': isInline}"
           v-if="error">{{ error }}</p>
      </div>
      <info-tooltip
          :helper-text="helperText"
          v-if="helperText"
      ></info-tooltip>
    </div>
    <div class="form-group" :class="{'row': isInline}" v-if="!isInline">
      <label :for="name" class="col-form-label font-size-lg" :class="{
      'col-lg-4 col-md-4 col-sm-12': isInline,
      'col-form-label-sm': size.toString() === 'sm',
      'col-form-label-lg': size.toString() === 'lg',
    }">{{ title }}<span class="text-danger" v-if="required">*</span>
        <info-tooltip
            :helper-text="helperText"
            v-if="helperText"
        ></info-tooltip>
      </label>
      <textarea
          :class="{
            'col-lg-8 col-md-8 col-sm-12': isInline,
                    'is-valid': isValid == null ? false : isValid,
                    'is-invalid': isValid == null ? false : !isValid,
                    'form-control-sm': size.toString() === 'sm',
                    'form-control-lg': size.toString() === 'lg',
                   }"
          :id="name"
          :rows="rows"
          :name="name"
          class="form-control"
          :required="required"
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="modelProxy"></textarea>
      <span class="form-text text-muted"
            :class="{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': isInline}">{{ hint }}</span>
      <p class="text-danger pt-1 px-3 block" :class="{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': isInline}"
         v-if="error">{{ error }}</p>

    </div>
  </div>
</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "TextareaInput",
  props: {
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    name: {},
    model: {},
    title: {},
    error: {},
    hint: {},
    placeholder: {},
    rows: {
      default: 4,
    },
    isValid: {
      required: false,
      default: null,
    },
    disabledErrorPopUp: {
      required: false,
      default: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    required: {
      default: true
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    isInline: {
      required: false,
      default: true,
    },
  },
  components: {
    InfoTooltip
  },
  computed: {
    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:model', value)
      }
    }
  },
}
</script>

<style scoped>

</style>
